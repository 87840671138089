import React from "react";
import "./PageTitle.css";

const PageTitle = ({ title, subtitle }) => {
  return (
    <div className=" flex justify-center items-center relative w-full ">
      <div className="flex flex-col justify-center items-center relative w-full">
        <h1 className="md:text-9xl text-5xl tracking-widest relative z-0 pageTitle">
          {title}
        </h1>
        <h1 className="text-white md:text-lg text-[9px] z-10 absolute tracking-[2px] md:tracking-[10px] subtitle ">
          {subtitle}
        </h1>
      </div>
    </div>
  );
};

export default PageTitle;
