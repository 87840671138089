import React, { useRef } from "react";
import { useFrame } from "@react-three/fiber";

export const Stars = ({ count = 1000, radius = 10 }) => {
  const meshRef = useRef();
  const vertices = new Float32Array(count * 3);

  for (let i = 0; i < count * 3; i++) {
    vertices[i] = (Math.random() - 0.5) * radius * 4;
  }

  useFrame(({ clock }) => {
    if (meshRef.current) {
      meshRef.current.rotation.z = clock.getElapsedTime() * 0.05;
      meshRef.current.rotation.x = clock.getElapsedTime() * 0.005;
    }
  });

  return (
    <points ref={meshRef}>
      <bufferGeometry>
        <bufferAttribute
          attachObject={["attributes", "position"]}
          array={vertices}
          count={count}
          itemSize={3}
        />
      </bufferGeometry>
      <pointsMaterial size={1} color="white" />
    </points>
  );
};

export const Stars2 = ({ count = 4000, radius = 10 }) => {
  const meshRef = useRef();
  const vertices = new Float32Array(count * 3);

  for (let i = 0; i < count * 3; i++) {
    vertices[i] = (Math.random() - 0.5) * radius * 4;
  }

  useFrame(({ clock }) => {
    if (meshRef.current) {
      meshRef.current.rotation.z = clock.getElapsedTime() * -0.03;
      meshRef.current.rotation.x = clock.getElapsedTime() * -0.005;
      meshRef.current.position.z = 10;
    }
  });

  return (
    <points ref={meshRef}>
      <bufferGeometry>
        <bufferAttribute
          attachObject={["attributes", "position"]}
          array={vertices}
          count={count}
          itemSize={3}
        />
      </bufferGeometry>
      <pointsMaterial size={1} color="white" />
    </points>
  );
};
