import React, { useEffect, useRef } from "react";
import { SwitchTransition, Transition } from "react-transition-group";
import { useLocation } from "react-router-dom";
import gsap from "gsap";
import { screendatas } from "./three/ScreenPlane";
import { bloomdatas } from "./three/postprocessing/BloomPass";
import { focusdatas } from "./three/postprocessing/FocusPass";
import { fxaadatas } from "./three/postprocessing/FXAAPass";

const isHomeRoute = (location) => location.pathname === "/";

const animateIterationTo = (
  dataObj,
  property,
  targetValue,
  duration,
  delay = 0
) => {
  const executeAnimation = () => {
    const startTime = performance.now();
    const startValue = dataObj[property];

    const updateIterations = () => {
      const currentTime = performance.now();
      const progress = (currentTime - startTime) / duration;
      const currentValue = startValue + (targetValue - startValue) * progress;
      dataObj[property] = currentValue;

      if (progress < 1) {
        requestAnimationFrame(updateIterations);
      } else {
        dataObj[property] = targetValue;
      }
    };

    requestAnimationFrame(updateIterations);
  };

  if (delay > 0) {
    setTimeout(() => {
      executeAnimation();
    }, delay);
  } else {
    executeAnimation();
  }
};

const TransitionComponent = ({ children }) => {
  const firstRender = useRef(true);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/") {
      animateIterationTo(screendatas, "solidness", 0.6, 700);
      animateIterationTo(screendatas, "ocValue", 0.8, 600);
      animateIterationTo(screendatas, "iterations", 312, 0, 0);
      animateIterationTo(screendatas, "inner", 3.0, 500);
      animateIterationTo(screendatas, "inner2", 3.0, 500);
    } else {
      animateIterationTo(screendatas, "solidness", 0.2, 700);
      animateIterationTo(screendatas, "ocValue", 0.1, 600);
      animateIterationTo(screendatas, "iterations", 0, 0, 600);
      animateIterationTo(screendatas, "inner", 0, 500);
      animateIterationTo(screendatas, "inner2", 0, 500);
      if (!firstRender.current) {
        // Additional code if needed
      } else {
        screendatas.solidness = 1;
        firstRender.current = false;
      }
    }
  }, [location]);

  return (
    <SwitchTransition>
      <Transition
        key={location.pathname}
        timeout={700}
        onEnter={(node) => {
          if (isHomeRoute === false) {
            bloomdatas.enabled = true;
            focusdatas.enabled = true;
            fxaadatas.enabled = false;
          }
          gsap.set(node, { autoAlpha: 0, scale: 1, yPercent: 100 });
          gsap
            .timeline({ paused: true })
            .to(node, { autoAlpha: 1, yPercent: 0, duration: 1 }, "-=0.15")
            .to(node, { scale: 1, duration: 0.35 }, "-=0.15")
            .play();
        }}
        onExit={(node) => {
          if (isHomeRoute) {
            bloomdatas.enabled = false;
            focusdatas.enabled = false;
            fxaadatas.enabled = true;
          }
          gsap
            .timeline({ paused: true })
            .to(node, { scale: 1, duration: 0.35 }, "-=0.15")
            .to(node, { yPercent: 100, autoAlpha: 0, duration: 1 }, "-=0.15")
            .play();
        }}
      >
        {children}
      </Transition>
    </SwitchTransition>
  );
};

export default TransitionComponent;
