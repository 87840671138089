import React, { useRef } from "react";
import * as THREE from "three";
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass";
import { extend, useFrame } from "@react-three/fiber";
import { GUIController } from "../../../modules/gui";

extend({ UnrealBloomPass });

export const bloomdatas = {
  enabled: false,
  exposure: 0.87,
  strength: 2,
  radius: 0.5,
  threshold: 0.6,
};

export const BloomPass = () => {
  const passRef = useRef(null);

  // const gui = GUIController.instance.setFolder("Bloom").open(false);
  // gui.addCheckBox(bloomdatas, "enabled");
  // gui.addNumericSlider(bloomdatas, "exposure", 0.1, 2, 0.01);
  // gui.addNumericSlider(bloomdatas, "strength", 0, 10, 0.1);
  // gui.addNumericSlider(bloomdatas, "radius", 0, 2, 0.01);
  // gui.addNumericSlider(bloomdatas, "threshold", 0, 1, 0.01);

  const update = (gl) => {
    passRef.current.enabled = bloomdatas.enabled;
    gl.toneMappingExposure = bloomdatas.enabled
      ? Math.pow(bloomdatas.exposure, 4.0)
      : 1;

    if (bloomdatas.enabled) {
      passRef.current.strength = bloomdatas.strength;
      passRef.current.radius = bloomdatas.radius;
      passRef.current.threshold = bloomdatas.threshold;
    }
  };

  useFrame(({ gl }) => {
    update(gl);
  });

  return <unrealBloomPass ref={passRef} attachArray="passes" />;
};
