import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { gsap } from "gsap";
import { CustomEase } from "gsap/CustomEase";
import "./Background.css";

export const Footer = () => {
  gsap.registerPlugin(CustomEase);

  useEffect(() => {
    gsap.from(".footer-link", {
      duration: 1.2,
      ease: CustomEase.create(
        "custom",
        "M0,0,C0.126,0.382,0.282,0.674,0.44,0.822,0.632,1.002,0.838,1.032,1,0.962"
      ),
      y: 200,
    });
  }, []);
  return (
    <div
      className="footerr"
      style={{
        position: "absolute",
        bottom: "3rem",
        textAlign: "center",
        width: "100%",
      }}
    >
      <div className="footer-container">
        <Link className="footer-link" to="/projects">
          Projects
        </Link>
        <Link className="footer-link" to="/education">
          Education
        </Link>
        <Link className="footer-link" to="/about">
          About
        </Link>
        {/* <Link className="footer-link" to="/templates">
          Fiverr
        </Link> */}
      </div>
    </div>
  );
};
