import React from "react";
import { Link } from "react-router-dom";

import "./Background.css";

export const Header = () => {
  return (
    <div className="header-container">
      <div className="header">
        <Link className="header-link" to="/">
          Home
        </Link>
        <Link className="header-link" to="/projects">
          Projects
        </Link>
        <Link className="header-link" to="/education">
          Education
        </Link>
        <Link className="header-link" to="/about">
          About
        </Link>
        {/* <Link className="header-link" to="/templates">
          Fiverr
        </Link> */}
      </div>
    </div>
  );
};
