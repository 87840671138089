import React from "react";
import "./CenteredImageWithText.css";

const CenteredImageWithText = ({ image, title, text }) => {
  return (
    <div className="centered-image-container">
      {title && <h2 className="centered-image-title">{title}</h2>}
      <img src={image} alt={title} className="centered-image" />
      <p className="centered-image-text">{text}</p>
    </div>
  );
};

export default CenteredImageWithText;
