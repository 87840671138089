// About.jsx
import React from "react";
import PageTitle from "../../page-builders/route-title/PageTitle.jsx";
import { Header } from "../../Header";
import Card from "../../page-builders/cards/Card.jsx";

import twitter from "../../../images/twitter.png";
import shopify from "../../../images/shopify.png";
import apple from "../../../images/apple.png";
import encrypt from "../../../images/encrypt.png";
import reddit from "../../../images/reddit.png";
import airbnb from "../../../images/airbnb.png";
import nike from "../../../images/nike.png";
import quantum from "../../../images/quantum.png";

const projects = [
  {
    id: "1",
    tools: "TYPESCRIPT | NEXT.JS 13 | TAILWINDCSS | SHADCN | CLERK",
    desc: "TWITTER CLONE",
    imageUrl: twitter,
  },
  {
    id: "2",
    tools: "REACT THREE FIBER | NEXT.JS | ECOMMERCE | BLENDER",
    desc: "SHOPIFY APP",
    imageUrl: shopify,
  },
  {
    id: "7",
    tools: "NEXT.JS | TAILWINDCSS | SHOPIFY ",
    desc: "NIKE CLONE",
    imageUrl: nike,
  },
  {
    id: "8",
    tools: "NEXT.JS | TAILWINDCSS | REACT THREE FIBER ",
    desc: "TECH WEBSITE WITH 3D MODELS",
    imageUrl: quantum,
  },
  {
    id: "3",
    tools: "JAVASCRIPT | REACT THREE FIBER",
    desc: "3D ENCRYPTION ANIMATION",
    imageUrl: encrypt,
  },
  {
    id: "5",
    tools: "RUBY ON RAILS | BOOTSTRAP | HEROKU",
    desc: "REDDIT CLONE",
    imageUrl: reddit,
  },
  {
    id: "6",
    tools: "RUBY ON RAILS | BOOTSTRAP | HEROKU | STRIPE",
    desc: "AIRBNB CLONE",
    imageUrl: airbnb,
  },
  {
    id: "4",
    tools: "NEXT.JS | TAILWINDCSS | GSAP",
    desc: "APPLE ESQUE WEBSITE",
    imageUrl: apple,
  },
];

export const Projects = () => {
  return (
    <div className="about-page page-content ">
      <div className="md:mb-32 mb-10">
        <Header />
        <PageTitle
          title="PROJECTS"
          subtitle="PROJECTS | COLLABORATIONS | EXPLORATIONS"
        />
      </div>
      <div className="flex flex-wrap justify-evenly max-w-[2000px] mx-auto">
        {projects.map((project) => (
          <Card
            bgImage={project.imageUrl}
            projectId={project.id}
            key={project.id}
            projectTools={project.tools}
            projectDesc={project.desc}
            clickable={true}
          />
        ))}
      </div>
    </div>
  );
};
