import { Suspense, useEffect, useRef } from "react";
import * as THREE from "three";
import { Stats } from "@react-three/drei";
import { Canvas, useFrame } from "@react-three/fiber";
import Effects from "./postprocessing/Effects";
import { BloomPass } from "./postprocessing/BloomPass";
import { FocusPass } from "./postprocessing/FocusPass";
// import { OrbitControls } from "@react-three/drei";
import { ScreenPlane } from "./ScreenPlane";
import { FXAAPass } from "./postprocessing/FXAAPass";

const OrthographicCamera = new THREE.OrthographicCamera(-1, 1, 1, -1, -10, 10);

const MouseMoveHandler = () => {
  const cameraRef = useRef();
  const mouse = useRef(new THREE.Vector2());

  useEffect(() => {
    const onMouseMove = (e) => {
      mouse.current.x = (e.clientX / window.innerWidth) * 2 - 1;
      mouse.current.y = -(e.clientY / window.innerHeight) * 2 + 1;
    };

    window.addEventListener("mousemove", onMouseMove);

    return () => {
      window.removeEventListener("mousemove", onMouseMove);
    };
  }, []);

  // useFrame(() => {
  //   if (cameraRef.current) {
  //     cameraRef.current.position.x +=
  //       (mouse.current.x * 10 - cameraRef.current.position.x) * 0.05;
  //     cameraRef.current.position.y +=
  //       (-mouse.current.y * 10 - cameraRef.current.position.y) * 0.05;
  //     cameraRef.current.lookAt(0, 0, 0);
  //   }
  // });

  return <orthographicCamera ref={cameraRef} />;
};

export const TCanvas = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Canvas
        className="canvas-container"
        camera={OrthographicCamera}
        dpr={1}
        style={{ position: "fixed" }}
      >
        {/* objects */}
        <ScreenPlane />
        {/* effects */}
        <Effects sRGBCorrection={false}>
          <FXAAPass />
          <BloomPass />
          <FocusPass />
        </Effects>

        {/* helper */}
        {/* <Stats /> */}
        {/* mouse move handler */}
        <MouseMoveHandler />
        {/* <OrbitControls /> */}
      </Canvas>
    </Suspense>
  );
};
