import React from "react";
import { Link } from "react-router-dom";
import { Tilt } from "react-tilt";

const defaultOptions = {
  reverse: false, // reverse the tilt direction
  max: 15, // max tilt rotation (degrees)
  perspective: 2000, // Transform perspective, the lower the more extreme the tilt gets.
  scale: 1.01, // 2 = 200%, 1.5 = 150%, etc..
  speed: 1000, // Speed of the enter/exit transition
  transition: true, // Set a transition on enter/exit.
  axis: null, // What axis should be disabled. Can be X or Y.
  reset: true, // If the tilt effect has to be reset on exit.
  easing: "cubic-bezier(.03,.98,.52,.99)", // Easing on enter/exit.
};

const Card = ({
  bgImage,
  projectId,
  projectTools,
  projectDesc,
  clickable = true,
}) => {
  const cardContent = (
    <div
      style={{ backgroundImage: `url(${bgImage})` }}
      className="text-white md:p-0 w-full h-[300px] md:h-[400px]  bg-cover bg-center mb-20 text-center overflow-hidden relative group rounded-lg"
    >
      <div className="absolute bottom-0 h-1/4 bg-black-half w-full lg:opacity-0 opacity-100 group-hover:opacity-100 transition-opacity duration-500 flex flex-col items-start">
        <p className="pl-3 pt-2 opacity-[0.9]">{projectDesc}</p>
        <p className="text-[#fff] opacity-[0.7] pl-3 pt-2 text-sm tracking-wider font-medium">
          {projectTools}
        </p>
      </div>
    </div>
  );

  if (clickable) {
    return (
      <Tilt
        options={defaultOptions}
        className="text-white md:w-[40%] w-full md:p-0 p-4"
      >
        <Link to={`/projects/${projectId}`} className="h-full w-1/2">
          {cardContent}
        </Link>
      </Tilt>
    );
  } else {
    return (
      <Tilt
        options={defaultOptions}
        className="text-white md:w-[40%] w-full md:p-0 p-4"
      >
        {cardContent}
      </Tilt>
    );
  }
};

export default Card;
