import { useRef } from "react";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";
import { FXAAShader } from "three/examples/jsm/shaders/FXAAShader";
import { extend, useFrame, useThree } from "@react-three/fiber";
import { GUIController } from "../../../modules/gui";
import { ShaderPassWrapper } from "./Effects";

extend({ ShaderPass });

export const fxaadatas = {
  enabled: true,
};

export const FXAAPass = () => {
  const passRef = useRef();
  const { size } = useThree();

  // const gui = GUIController.instance.setFolder("FXAA").open(false);
  // gui.addCheckBox(fxaadatas, "enabled");

  const update = () => {
    const pass = passRef.current;
    pass.enabled = fxaadatas.enabled;

    if (fxaadatas.enabled) {
    }
  };

  useFrame(() => {
    update();
  });

  return (
    <ShaderPassWrapper
      ref={passRef}
      attachArray="passes"
      args={[FXAAShader]}
      uniforms-resolution-value={[1 / size.width, 1 / size.height]}
    />
  );
};
