import React from "react";
import * as THREE from "three";
import { Plane } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { GUIController } from "../../modules/gui";
import { Stars, Stars2 } from "./Stars";

const rand = (min, max, digit) => {
  let num = Math.random() * (max - min) + min;
  num = Number(num.toFixed(digit));
  return num;
};

export const screendatas = {
  random: () => {
    screendatas.scaleX = rand(-50, 50, 1);
    screendatas.scaleY = rand(-50, 50, 1);
    screendatas.scaleZ = rand(-50, 50, 1);
    screendatas.distortion = rand(0, 1, 2);
    screendatas.creepiness = rand(0, 1, 2) > 0.5;
  },
  scaleX: 5,
  scaleY: 5,
  scaleZ: 5,
  distortion: 0.23,
  creepiness: false,
  rotation: true,
  iterations: 256 * 2,
  ocValue: 1.0,
  powValue: 3.0,
  solidness: 0.6,
  inner: 3.0,
  inner2: 3.0,
};

export const ScreenPlane = () => {
  // const gui = GUIController.instance.setFolder("Uniforms");
  // gui.addNumericSlider(screendatas, "ocValue", 0, 2.0, 0).listen();
  // gui.addNumericSlider(screendatas, "powValue", 0.0, 20.0, 1).listen();
  // gui.addNumericSlider(screendatas, "solidness", 0.0, 20.0, 1).listen();
  // gui.addButton(screendatas, "random");
  // gui.addNumericSlider(screendatas, "scaleX", -50, 50, 0.1, "scale x").listen();
  // gui.addNumericSlider(screendatas, "scaleY", -50, 50, 0.1, "scale y").listen();
  // gui.addNumericSlider(screendatas, "scaleZ", -50, 50, 0.1, "scale z").listen();
  // gui.addNumericSlider(screendatas, "distortion", 0, 10, 0.01).listen();
  // gui.addCheckBox(screendatas, "creepiness").listen();
  // gui.addCheckBox(screendatas, "rotation");
  // gui.addNumericSlider(screendatas, "iterations", 0, 256).listen();

  const shader = {
    uniforms: {
      u_ocValue: { value: screendatas.ocValue },
      u_powValue: { value: screendatas.powValue },
      u_solidness: { value: screendatas.zoom },
      u_time: { value: 0 },
      u_aspect: { value: 0 },
      u_mouse: { value: new THREE.Vector2(0, 0) },
      u_scale: { value: new THREE.Vector3() },
      u_distortion: { value: screendatas.distortion },
      u_creepiness: { value: screendatas.creepiness },
      u_iterations: { value: screendatas.iterations },
      u_inner: { value: screendatas.inner },
      u_inner2: { value: screendatas.inner2 },
    },
    vertexShader: vertexShader,
    fragmentShader: fragmentShader,
  };

  const vec = new THREE.Vector2();
  useFrame(({ size, mouse }) => {
    shader.uniforms.u_ocValue.value = screendatas.ocValue;
    shader.uniforms.u_powValue.value = screendatas.powValue;
    shader.uniforms.u_solidness.value = screendatas.solidness;
    shader.uniforms.u_inner.value = screendatas.inner;
    shader.uniforms.u_inner2.value = screendatas.inner2;
    screendatas.rotation && (shader.uniforms.u_time.value += 0.005);
    shader.uniforms.u_aspect.value = size.width / size.height;
    shader.uniforms.u_mouse.value.lerp(vec.set(mouse.x / 2, mouse.y / 2), 0.05);
    screendatas.scaleX = 5 + Math.sin(shader.uniforms.u_time.value * 0.15) * 10; // Slowly change scaleX
    screendatas.scaleY = 5 + Math.sin(shader.uniforms.u_time.value * 0.39) * 5; // Slowly change scaleY
    screendatas.scaleZ = 5 + Math.sin(shader.uniforms.u_time.value / 5.5) * 5; // Slowly change scaleZ
    // screendatas.distortion = 0.25 + Math.sin(shader.uniforms.u_time.value * 0.5) * 1;
    shader.uniforms.u_scale.value.set(
      screendatas.scaleX,
      screendatas.scaleY,
      screendatas.scaleZ
    );
    shader.uniforms.u_distortion.value = screendatas.distortion;
    shader.uniforms.u_creepiness.value = screendatas.creepiness;
    shader.uniforms.u_iterations.value = screendatas.iterations;
  });

  return (
    <>
      <Stars2 count={5000} radius={1} />
      <Stars count={5000} radius={1} />

      <Plane args={[2, 2]}>
        <shaderMaterial args={[shader]} />
      </Plane>
    </>
  );
};

const vertexShader = `
varying vec2 v_uv;

void main() {
  v_uv = uv;
  gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
}
`;
const fragmentShader = `
uniform float u_time;
uniform float u_aspect;
uniform vec2 u_mouse;
uniform vec3 u_scale;
uniform float u_distortion;
uniform bool u_creepiness;
varying vec2 v_uv;
uniform int u_iterations;
uniform float u_ocValue;
uniform float u_powValue;
uniform float u_solidness;
uniform float u_inner;
uniform float u_inner2;


const float PI = 3.14159265358979;

// Rotate function
mat4 rotationMatrix(vec3 axis, float angle) {
  axis = normalize(axis);
  float s = sin(angle);
  float c = cos(angle);
  //changes zoom
  float oc = u_ocValue - c;
  
  return mat4(oc * axis.x * axis.x + c,           oc * axis.x * axis.y - axis.z * s,  oc * axis.z * axis.x + axis.y * s,  0.0,
              oc * axis.x * axis.y + axis.z * s,  oc * axis.y * axis.y + c,           oc * axis.y * axis.z - axis.x * s,  0.0,
              oc * axis.z * axis.x - axis.y * s,  oc * axis.y * axis.z + axis.x * s,  oc * axis.z * axis.z + c,           0.0,
              0.0,                                0.0,                                0.0,                                1.0);
}

vec3 rotate(vec3 v, vec3 axis, float angle) {
  mat4 m = rotationMatrix(axis, angle);
  return (m * vec4(v, 1.0)).xyz;
}

// Fresnel function
float fresnel(vec3 eye, vec3 normal) {
  //makes the center darker / lighter when changing 1.1
  return pow(1.1 + dot(eye, normal), u_powValue);
}


// polynomial smooth min 1 (k=0.1)
float smin( float a, float b, float k ) {
  //change the 0.05 and 0.5 to change the smoothness
  float h = clamp( 0.5+0.5*(b-a)/k, 0.07, 1.0 );
  return mix( b, a, h ) - k*h*(1.0-h);
}

float opUnion( float d1, float d2 ) { return min(d1,d2); }

float opSubtraction( float d1, float d2 ) { return max(-d1,d2); }

float opIntersection( float d1, float d2 ) { return max(d1,d2); }

float opSmoothSubtraction( float d1, float d2, float k ) {
  float h = clamp( 0.5 - 0.5*(d2+d1)/k, 0.0, 1.0 );
  return mix( d2, -d1, h ) + k*h*(1.0-h);
}

float sdSphere(vec3 p, float r) {
  return length(p) - r;
}

float gyroid(in vec3 p, float t) {
  vec3 scale = u_scale + 1.0;
  //change this
  p *= scale * u_solidness;
  vec3 p2 = mix(p, p.yzx, u_distortion);
  
  float g;
  if (u_creepiness) g = abs(dot(sin(p), cos(p2)) / length(scale)) - 0.04;
  else              g = dot(sin(p), cos(p2)) / length(scale);

  return g;
}

float sdf(vec3 p) {
  vec3 rp = rotate(p, vec3(0.3, 1.0, 0.2), u_time * 0.3);
  float t = (sin(u_time * 0.5 + PI / 2.0) + 1.0) * 0.5; // 0 ~ 1
  
  float sphere = sdSphere(p, 1.0);
  float g = gyroid(rp, t);

  float dist = smin(sphere, g, -0.01) + 0.03;
  float dist2 = smin(sphere, -g, -0.01) + 0.03;

  return opUnion(dist, dist2);
}

vec3 calcNormal(in vec3 p) {
  const float h = 0.0001;
  const vec2 k = vec2(1, -1) * h;
  return normalize( k.xyy * sdf( p + k.xyy ) + 
                    k.yyx * sdf( p + k.yyx ) + 
                    k.yxy * sdf( p + k.yxy ) + 
                    k.xxx * sdf( p + k.xxx ) );
}

void main() {
  vec2 centeredUV = (v_uv - 0.5) * vec2(u_aspect, 1.0);
  vec3 ray = normalize(vec3(centeredUV, -1.0));

  vec2 m = u_mouse * vec2(u_aspect, 1.0) * 0.07;
  ray = rotate(ray, vec3(1.0, 0.0, 0.0), m.y);
  ray = rotate(ray, vec3(0.0, 1.0, 0.0), -m.x);

  vec3 camPos = vec3(0.0, 0.0, 3.5);
  
  vec3 rayPos = camPos;
  float totalDist = 0.0;
  float tMax = 5.0;

  float camDist = distance(camPos, rayPos);
  float adaptiveStep = mix(0.0001, 0.01, clamp(camDist / 10.0, 0.0, 1.0));

  for (int i = 0; i < u_iterations; i++) {
    float dist = sdf(rayPos);

    if (dist < adaptiveStep || tMax < totalDist) break;

    totalDist += dist;
    rayPos = camPos + totalDist * ray;
  }

  vec3 color = vec3(0.07, 0.25, 0.45);

  float cLen = length(centeredUV);
  cLen = 1.1 - smoothstep(0.2, 0.7, cLen);
  color *= vec3(cLen);

  if(totalDist < tMax) {
    vec3 normal = calcNormal(rayPos);
    float diff = dot(vec3(1.0), normal) ;

    float d = length(rayPos);
    //change 0.5 to remove orange middle
    d = smoothstep(0.9, 0.9, d);
    color = mix(vec3(0.0, u_inner, u_inner2), vec3(0.0, 0.0, 0.1), d);
    
    float _fresnel = fresnel(ray, normal);
    color += vec3(0.5, 1.0, 1.0) * _fresnel * 0.8 * diff;
  }

  gl_FragColor = vec4(color, 1.0);
}
`;
