import React, { Suspense, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Header } from "../../Header";
import ReactPlayer from "react-player";

import * as assets from "../../../assets/assets.js";

const ProjectDetails = () => {
  const { projectId } = useParams();

  // Simulated project data
  const projects = {
    1: {
      title: "TWITTER CLONE",
      languages: "TYPESCRIPT | THREE.JS | TAILWINDCSS | CLERK",
      description:
        "This app is a clone of the social app Twitter, developed utilizing Next.js 13 as the primary framework. The system integrates Clerk for secure user authentication, TailwindCSS and Shadcn for styling, and leverages MongoDB as the backend database solution. Among its feature set, the application supports post creation and deletion, enables user commenting on individual posts, offers real-time notifications for comment interactions, and has the ability for community creation",
      github: "https://github.com/Marcus-Ayers/twitter.git",
      demo: "https://twitter-five-lac.vercel.app/",
      video: assets.twitterVideo,
      gallery: [
        assets.twitter,
        assets.twitter2,
        assets.twitter3,
        assets.twitter4,
        assets.twitter5,
        assets.twitter6,
        assets.twitter7,
      ],
    },
    2: {
      title: "SHOPIFY APP",
      languages: "REACT THREE FIBER | NEXT.JS | ECOMMERCE | BLENDER",
      github: "https://github.com/Marcus-Ayers/shopify-starter-2.git",
      demo: "https://commerce-wheat-ten-38.vercel.app/",
      description:
        "This application is a Shopify app developed off of the Vercel Commerce starter template. I've modified the starter template to have a more modern look, and have created custom 3D models that match with some of the products featured in the store. Utilizing Shopify's API keys, the application is able to show all the products specific to an associated Shopify storefront. The application is built on Next.js, TailwindCSS employed for styling and interactive UI elements.",
      video: assets.shopifyVideo2,
      gallery: [assets.shopify, assets.shopify2],
    },
    3: {
      title: "ENCRYPTION ANIMATION",
      languages: "REACT THREE FIBER | JAVASCRIPT",
      github: "https://github.com/Marcus-Ayers/encrypt-animation.git",
      demo: "https://encrypt-animation.vercel.app/",
      description:
        "This project involves creating an animation using CSS and React Three Fiber. The aim is to simulate the encryption of an ID card as it crosses the middle of the screen.",

      video: assets.encryptVideo,
      gallery: [assets.encrypt],
    },
    4: {
      title: "APPLE ESQUE WEBSITE",
      languages: "REACT THREE FIBER | NEXT.JS | ECOMMERCE | BLENDER",
      github: "https://github.com/Marcus-Ayers/nexusmax.git",
      demo: "https://nexusmax-ma.vercel.app/",
      description:
        "This application replicates the design of a page on Apple's website, featuring cards that display most of the information. It employs the GSAP library to enhance the user experience with scrolling animations.",
      video: assets.appleVideo,
      gallery: [assets.apple],
    },
    5: {
      title: "REDDIT CLONE",
      languages: "REACT THREE FIBER | NEXT.JS | ECOMMERCE | BLENDER",
      github: "https://github.com/Marcus-Ayers/reddit-app-v2.git",
      demo: "https://marcusayersredditv2.herokuapp.com/",
      description:
        "This project is one of my initial large-scale applications, built using Ruby on Rails to replicate key features of Reddit. The application includes user authentication for sign-up, and supports functionalities like post creation, deletion, and commenting. Users also have the capability to create and manage their own 'Subreddits,' allowing for posts to be made in specific communities.",
      video: assets.redditVideo,
      gallery: [
        assets.reddit,
        assets.reddit2,
        assets.reddit3,
        assets.reddit4,
        assets.reddit5,
      ],
    },
    6: {
      title: "AIRBNB CLONE",
      languages: "REACT THREE FIBER | NEXT.JS | ECOMMERCE | BLENDER",
      github: "https://github.com/Marcus-Ayers/fswd-airbnb-clone.git",
      demo: "",
      description:
        "This project is an Airbnb clone developed using Ruby on Rails. The application allows users to create their own listings and book stays at available listings. Additionally, it integrates Stripe for secure payment processing.",
      image: assets.airbnb,
      gallery: [],
    },
    7: {
      title: "NIKE SHOPIFY APP",
      languages: "NEXT.JS | TAILWINDCSS| ECOMMERCE ",
      github: "https://github.com/Marcus-Ayers/nike-shopify-store.git",
      demo: "https://nike-shopify-store.vercel.app/",
      description:
        "This version of my Shopify template features a Nike-themed design. It is constructed using Next.js and TailwindCSS, and integrates Shopify's API to display product listings.",
      video: assets.nikeVideo,
      gallery: [assets.nike, assets.nike2],
    },
    8: {
      title: "QUANTUM DYANMICS",
      languages: "NEXT.JS | TAILWINDCSS| REACT THREE FIBER ",
      github: "https://github.com/Marcus-Ayers/quantum-dynamics.git",
      demo: "https://quantum-dynamics-sand.vercel.app/",
      description:
        "This project involves the development of a website for a hypothetical tech company specializing in Software-as-a-Service (SaaS) solutions. To enrich the user experience and visual engagement, the site incorporates custom 3D models using React Three Fiber. For fluid and interactive animations, Framer Motion has been utilized. It also has a working contact form using EmailJS.",
      video: assets.quantumVideo,
      gallery: [assets.quantum, assets.quantum2],
    },
  };

  // Fetch the relevant project data based on the projectId from the URL
  const project = projects[projectId];

  const [lastProject, setLastProject] = useState(null);

  useEffect(() => {
    if (project) {
      setLastProject(project);
    }
  }, []);

  const displayProject = project || lastProject;

  // if (!project) {
  //   return <div>Project not found</div>;
  // }

  return (
    <Suspense>
      <div className="px-3">
        <div className="md:mb-32 mb-10">
          <Header />
        </div>
        <div className="text-white text-4xl flex flex-col justify-center items-center">
          <div>
            <h1 className="project md:text-[3.5vw] text-3xl md:tracking-[0.7vw] tracking-tight font-light md:mb-5">
              {displayProject?.title}
            </h1>
          </div>
          <h1 className="languages md:text-[1vw] text-xs md:tracking-[0.8vw] tracking-tight mb-10">
            {displayProject?.languages}
          </h1>
          <div className="max-w-5xl">
            {displayProject?.video ? (
              <ReactPlayer
                className="react-player"
                url={displayProject?.video}
                controls
              />
            ) : (
              <img src={displayProject?.image} alt="Default display" />
            )}
          </div>

          <div className="flex mt-10 md:w-1/4 w-1/2 items-center justify-around md:text-[20px] text-[15px] text-[#fff] opacity-[0.7]">
            <a
              className="hover:text-[#9a9a9a] transition-colors duration-300 hover:pointer"
              href={displayProject?.github}
              target="_blank"
              rel="noreferrer"
            >
              GitHub
            </a>
            <a
              className="hover:text-[#9a9a9a] transition-colors duration-300 hover:pointer"
              href={displayProject?.demo}
              target="_blank"
              rel="noreferrer"
              onClick={(event) => {
                if (!displayProject?.demo) {
                  event.preventDefault();
                  alert("Project is not deployed");
                }
              }}
            >
              Live Demo
            </a>
          </div>
          <p className="mt-10 md:mb-32 mb-10 text-[#fff]  opacity-[0.7] max-w-4xl text-sm">
            {displayProject?.description}
          </p>
          <div className="flex flex-wrap justify-evenly w-full">
            {displayProject?.gallery.map((imgUrl, index) => (
              <div key={index} className="md:my-10 mb-20 w-[100%] md:w-[45%]">
                <img
                  className="rounded-md w-full"
                  src={imgUrl}
                  alt={`Gallery item ${index}`}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Suspense>
  );
};

export default ProjectDetails;
