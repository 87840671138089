import React, { Suspense } from "react";
import PageTitle from "../../page-builders/route-title/PageTitle";
import CenteredImageWithText from "../../page-builders/center-image-text/CenteredImageWithText";
import { Header } from "../../Header";
import me from "../../../images/me.jpg";
import SocialIcons from "../../page-builders/social-icons/SocialIcons";

export const About = () => {
  return (
    <div className="page-content">
      <Header />
      <PageTitle title="ABOUT" subtitle="PERSONAL | OVERVIEW" />
      <SocialIcons />
      <Suspense>
        <CenteredImageWithText
          image={me}
          title="Marcus Ayers"
          text="I am a committed and self-driven web developer with a strong inclination towards leveraging cutting-edge technologies to deliver effective solutions. With a background in freelance work and an expanding expertise in full-stack development, I bring a wealth of hands-on experience to the table. I have recently worked extensively with Next.js 13, enabling me to build performant and scalable applications. With a background of knowledge using Javascript, React, and Ruby on Rails. In addition, I possess foundational knowledge in React Three Fiber and Three.js, which I have begun to integrate into my projects."
        />
      </Suspense>
      <section style={{ height: "100px" }}></section>
    </div>
  );
};
