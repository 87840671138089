// About.jsx
import React from "react";
import PageTitle from "../../page-builders/route-title/PageTitle.jsx";
import { Header } from "../../Header";
import Card from "../../page-builders/cards/Card.jsx";

import capstone from "../../../images/capstone.png";
import javascript from "../../../images/javascript.png";
import react from "../../../images/react.png";
import rubyonrails from "../../../images/rubyonrails.png";

const projects = [
  {
    id: "10",
    tools: "50hrs",
    desc: "JAVASCRIPT",
    imageUrl: javascript,
  },
  {
    id: "9",
    tools: "30hrs",
    desc: "REACT",
    imageUrl: react,
  },
  {
    id: "8",
    tools: "50hrs",
    desc: "RUBY ON RAILS",
    imageUrl: rubyonrails,
  },
  {
    id: "7",
    tools: "30hrs",
    desc: "CAPTSONE PROJECT",
    imageUrl: capstone,
  },
];

export const Education = () => {
  return (
    <div className="about-page page-content">
      <div className="mb-32">
        <Header />
        <PageTitle
          title="EDUCATION"
          subtitle="EXPERIENCE | CERTIFICATES | CREDENTIALS"
        />
      </div>
      <div className="flex flex-wrap justify-evenly">
        {projects.map((project) => (
          <Card
            bgImage={project.imageUrl}
            projectId={project.id}
            key={project.id}
            projectTools={project.tools}
            projectDesc={project.desc}
            clickable={false}
          />
        ))}
      </div>
    </div>
  );
};
