import React, { useEffect, useRef } from "react";
import "./CenteredText.css";

export const CenteredText = ({ mainText, subheadingText }) => {
  const mainTextRef = useRef();
  const subTextRef = useRef();

  useEffect(() => {
    mainTextRef.current.style.transform = "translateY(0)";
    mainTextRef.current.style.opacity = "1";

    const timeout = setTimeout(() => {
      subTextRef.current.style.transform = "translateY(0)";
      subTextRef.current.style.opacity = "1";
    }, 500);

    return () => clearTimeout(timeout);
  }, []);

  const textStyles = {
    transition: "transform 0.5s ease-out, opacity 0.5s ease-out",
    transform: "translateY(-2rem)",
    opacity: 0,
    textAlign: "center",
  };

  const containerStyle = {
    position: "relative",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <div style={containerStyle}>
      <h1
        className="heading name"
        ref={mainTextRef}
        style={{
          ...textStyles,
          fontSize: "5rem",
          // color: "white",
          // letterSpacing: "0.1rem",
        }}
      >
        {mainText}
      </h1>
      <h2
        className="subheading name"
        ref={subTextRef}
        style={{
          ...textStyles,
          fontSize: "1rem",
          // color: "lightGray",
          letterSpacing: "20px",
        }}
      >
        {subheadingText}
      </h2>
    </div>
  );
};
