import React from "react";
import { Routes, Route } from "react-router-dom";
import { Home } from "./pages/home/Home.jsx";
import { About } from "./pages/about/About.jsx";
import { Projects } from "./pages/projects/Projects.jsx";
import { Education } from "./pages/education/Education.jsx";
import { Templates } from "./pages/templates/Templates.jsx";
import { TCanvas } from "./three/TCanvas";
import TransitionComponent from "./TransitionComponent";
import ProjectDetail from "./page-builders/projects/Project.jsx";

export const App = () => {
  return (
    <div style={{ width: "100vw", height: "100vh", position: "relative" }}>
      <TCanvas />
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
      >
        <Routes>
          <Route
            path="/"
            element={
              <TransitionComponent>
                <Home />
              </TransitionComponent>
            }
          />
          <Route
            path="/about"
            element={
              <TransitionComponent>
                <About />
              </TransitionComponent>
            }
          />
          <Route
            path="/projects"
            element={
              <TransitionComponent>
                <Projects />
              </TransitionComponent>
            }
          />
          <Route
            path="/education"
            element={
              <TransitionComponent>
                <Education />
              </TransitionComponent>
            }
          />
          <Route
            path="/templates"
            element={
              <TransitionComponent>
                <Templates />
              </TransitionComponent>
            }
          />
          <Route
            path="/projects/:projectId"
            element={
              <TransitionComponent>
                <ProjectDetail />
              </TransitionComponent>
            }
          />
        </Routes>
      </div>
    </div>
  );
};
