import { useEffect, useRef } from "react";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";
import { GammaCorrectionShader } from "three/examples/jsm/shaders/GammaCorrectionShader";
import { extend, useFrame, useThree } from "@react-three/fiber";
import React, { forwardRef } from "react";

extend({ EffectComposer, RenderPass });

export const ShaderPassWrapper = forwardRef(
  ({ attachArray, args, ...props }, ref) => {
    const shaderPass = React.useMemo(() => new ShaderPass(...args), [args]);

    React.useImperativeHandle(ref, () => shaderPass);

    return (
      <primitive
        ref={ref}
        object={shaderPass}
        attachArray={attachArray}
        {...props}
      />
    );
  }
);

const Effects = (props) => {
  const { children, sRGBCorrection } = props;

  const composerRef = useRef(null);
  const { gl, scene, camera, size } = useThree();

  useEffect(() => {
    composerRef.current.setSize(size.width, size.height);
  }, [size]);

  useFrame(() => {
    composerRef.current.render();
  }, 1);

  return (
    <effectComposer ref={composerRef} args={[gl]}>
      <renderPass attachArray="passes" args={[scene, camera]} />
      {sRGBCorrection && (
        <ShaderPassWrapper
          attachArray="passes"
          args={[GammaCorrectionShader]}
        />
      )}
      {children}
    </effectComposer>
  );
};

export default Effects;
