import React, { Suspense } from "react";
import { CenteredText } from "../../page-builders/center-text/CenteredText.jsx";
import { Footer } from "../../Footer";

export const Home = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <div className="">
        <CenteredText
          mainText="Marcus Ayers"
          subheadingText="FULL-STACK WEB DEVELOPER"
        />
        <Footer />
      </div>
    </Suspense>
  );
};
